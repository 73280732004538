var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"2"}},[_c('div',{staticClass:"roundup-logo"}),_c('h4',{staticClass:"attention_call_18"},[_vm._v("Quick signup")]),_c('p',{staticClass:"description_text"},[_vm._v(" Create your account, verify your "),_c('strong',[_vm._v("501(c)(3)")]),_vm._v(" organization’s details, and connect a payment account to receive your donations. ")]),_c('div',{staticClass:"attention_call_18"},[_vm._v("Effortless donations")]),_c('p',{staticClass:"description_text"},[_vm._v(" RoundUp App processes donations monthly after your initial signup with no extra work for your team. ")]),_c('h4',{staticClass:"attention_call_18"},[_vm._v("Raise awareness")]),_c('p',{staticClass:"description_text"},[_vm._v(" Join the thousands of nonprofits using RoundUp App to signup new donors and share your organization’s mission. ")]),_c('a',{style:({
				'text-decoration': 'none'
			}),attrs:{"target":"_blank","href":"https://www.roundupapp.com/for-nonprofits"}},[_vm._v("Learn more about RoundUp App")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"register-view"},[_c('v-card-title',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.getSignUpText)+" "),_c('p',{staticStyle:{}},[_vm._v(_vm._s(_vm.getSignUpParagraph))])]),_c('v-card-text',[(_vm.showSignup)?_c('div',[_c('div',{staticClass:"attention_call_16"},[_vm._v(" Create your RoundUp App account ")]),_c('div',{staticClass:"register-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"First Name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Last Name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"type":"number","placeholder":"Phone"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('ui-label',[_c('ui-input',{attrs:{"placeholder":"Password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('div',{staticClass:"checkbox tos"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.tos_accept),expression:"user.tos_accept"}],style:({
										fontsize: '16px'
									}),attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.user.tos_accept)?_vm._i(_vm.user.tos_accept,null)>-1:(_vm.user.tos_accept)},on:{"change":function($event){var $$a=_vm.user.tos_accept,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, "tos_accept", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, "tos_accept", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, "tos_accept", $$c)}}}}),_vm._v(" I agree to the "),_c('a',{attrs:{"target":"_blank","href":"https://roundupapp.com/terms/"}},[_vm._v("Terms of Use")]),_vm._v(" and "),_c('a',{attrs:{"target":"_blank","href":"https://roundupapp.com/platform-nonprofits/"}},[_vm._v("Platform Agreement")])]),_c('ui-label',[_c('Roundup-Button',{style:({
										width: '100%',
										'margin-top': '30px !important'
									}),attrs:{"type":"submit","color":"primary","loading":_vm.isLoading}},[_vm._v("Create Account")])],1)],1)])]):_c('div',[_c('div',{staticClass:"attention_call_16",staticStyle:{"margin-top":"50px"}},[_vm._v(" "+_vm._s(_vm.partnershipInstitution.name)+" is already on RoundUp App ")]),_c('p',[_vm._v(" This organization already has a profile on RoundUp App. Please contact the admin that created the profile in order to be added to the team. ")]),_c('p',[_vm._v(" Need more help? Use the chat icon below or email us at "),_c('a',{attrs:{"href":"mailto:support@roundupapp.com"}},[_vm._v("support@roundupapp.com")]),_vm._v(". ")])]),_c('p',{staticClass:"existing-account"},[_vm._v(" Have an account? "),_c('router-link',{style:({
							'text-decoration': 'none'
						}),attrs:{"to":{ name: 'login' }}},[_vm._v(" Log in ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }