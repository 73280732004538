<template>
	<v-row>
		<v-col cols="4" offset="2">
			<div class="roundup-logo"></div>
			<h4 class="attention_call_18">Quick signup</h4>
			<p class="description_text">
				Create your account, verify your
				<strong>501(c)(3)</strong> organization’s details, and connect a
				payment account to receive your donations.
			</p>
			<div class="attention_call_18">Effortless donations</div>
			<p class="description_text">
				RoundUp App processes donations monthly after your initial
				signup with no extra work for your team.
			</p>
			<h4 class="attention_call_18">Raise awareness</h4>
			<p class="description_text">
				Join the thousands of nonprofits using RoundUp App to signup new
				donors and share your organization’s mission.
			</p>
			<a
				v-bind:style="{
					'text-decoration': 'none'
				}"
				target="_blank"
				href="https://www.roundupapp.com/for-nonprofits"
				>Learn more about RoundUp App</a
			>
		</v-col>
		<v-col cols="4">
			<v-card class="register-view">
				<v-card-title class="form-title">
					{{ getSignUpText }}
					<p style="subtitle">{{ getSignUpParagraph }}</p>
				</v-card-title>
				<v-card-text>
					<div v-if="showSignup">
						<div class="attention_call_16">
							Create your RoundUp App account
						</div>
						<div class="register-content">
							<form v-on:submit.prevent="onSubmit">
								<ui-label>
									<ui-input
										v-model="user.first_name"
										placeholder="First Name"
									/>
								</ui-label>
								<ui-label>
									<ui-input
										v-model="user.last_name"
										placeholder="Last Name"
									/>
								</ui-label>
								<ui-label>
									<ui-input
										type="number"
										v-model="user.phone"
										placeholder="Phone"
									></ui-input>
								</ui-label>
								<ui-label>
									<ui-input
										v-model="user.email"
										placeholder="Email"
									/>
								</ui-label>
								<ui-label>
									<ui-input
										v-model="user.password"
										placeholder="Password"
										type="password"
									/>
								</ui-label>
								<div class="checkbox tos">
									<input
										v-bind:style="{
											fontsize: '16px'
										}"
										type="checkbox"
										v-model="user.tos_accept"
									/>
									I agree to the
									<a
										target="_blank"
										href="https://roundupapp.com/terms/"
										>Terms of Use</a
									>
									and
									<a
										target="_blank"
										href="https://roundupapp.com/platform-nonprofits/"
										>Platform Agreement</a
									>
								</div>
								<ui-label>
									<Roundup-Button
										v-bind:style="{
											width: '100%',
											'margin-top': '30px !important'
										}"
										type="submit"
										color="primary"
										:loading="isLoading"
										>Create Account</Roundup-Button
									>
								</ui-label>
							</form>
						</div>
					</div>
					<div v-else>
						<div class="attention_call_16" style="margin-top: 50px">
							{{ partnershipInstitution.name }} is already on
							RoundUp App
						</div>
						<p>
							This organization already has a profile on RoundUp
							App. Please contact the admin that created the
							profile in order to be added to the team.
						</p>
						<p>
							Need more help? Use the chat icon below or email us
							at
							<a href="mailto:support@roundupapp.com"
								>support@roundupapp.com</a
							>.
						</p>
					</div>
					<p class="existing-account">
						Have an account?
						<router-link
							:to="{ name: 'login' }"
							v-bind:style="{
								'text-decoration': 'none'
							}"
						>
							Log in
						</router-link>
					</p>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapActions } from 'vuex'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiToggle from '../../ui/Toggle'
import Screen from '../../ui/Screen'
import axios from '../../../network/axios'
import { getUTMParams } from '../../../utilities'

export default {
	name: 'register-view',
	components: {
		UiButton,
		UiInput,
		UiLabel,
		UiToggle,
		Screen
	},
	props: {
		uuid: String
	},
	mounted() {
		this.partnershipFound()
	},
	computed: {
		showSignup() {
			if (this.partnership) {
				if (
					this.partnershipInstitution &&
					this.partnershipInstitution.unclaimed_profile === 0
				) {
					return false
				}
			}
			return true
		},
		getSignUpText() {
			if (this.partnership && this.partnershipError === false) {
				return (
					this.toTitleCase(this.partnershipMerchant.name) +
					' Wants to Donate to You!'
				)
			} else {
				return 'Sign Up for RoundUps!'
			}
		},
		getSignUpParagraph() {
			if (this.partnership && this.partnershipError === false) {
				return (
					this.toTitleCase(this.partnershipMerchant.name) +
					' is using RoundUp at Checkout to enable customers to donate ' +
					'the spare change from purchases to ' +
					this.toTitleCase(this.partnershipInstitution.name) +
					'.'
				)
			} else {
				return ''
			}
		}
	},
	data() {
		return {
			partnershipInstitution: null,
			partnershipMerchant: null,
			isLoading: false,
			DonatingTo: this.$route.query.DonatingTo
				? this.$route.query.DonatingTo
				: null,
			workingWith: this.$route.query.WorkingWith
				? this.$route.query.WorkingWith
				: null,
			partnership: false,
			partnershipError: false,
			user: {
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				institution: this.uuid,
				phone: '',
				request_contact: true,
				tos_accept: false
			},
			institution: null
		}
	},
	methods: {
		...mapActions(['register', 'getInstitution']),
		learnMore() {
			window.open('https://www.roundupapp.com/for-nonprofits', '_blank')
		},
		async partnershipFound() {
			if (this.DonatingTo != null && this.workingWith != null) {
				const inst = await this.getInstitution(this.DonatingTo)
				const merchant = await this.getMerchant(this.workingWith)
				this.partnershipMerchant = merchant
				this.partnershipInstitution = inst
				if (
					merchant &&
					merchant.institution &&
					merchant.institution[0].id === inst.id
				) {
					this.partnership = true
				}
			}
		},
		async getInstitution(id) {
			return new Promise((resolve, reject) => {
				axios
					.get('/institutions/lookup/' + id)
					.then(({ data }) => {
						resolve(data)
					})
					.catch(({ reject }) => {
						this.partnershipError = true
					})
			})
		},
		async getMerchant(id) {
			return new Promise((resolve, reject) => {
				axios
					.get('/merchants/' + id)
					.then(({ data }) => {
						resolve(data)
					})
					.catch(({ reject }) => {
						this.partnershipError = true
					})
			})
		},
		toTitleCase(str) {
			return str.replace(/\w\S*/g, function(txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
			})
		},
		onSubmit() {
			this.isLoading = true
			if (!this.user.tos_accept) {
				this.isLoading = false
				this.setAlert({
					alertText:
						'In order to continue, please accept our agreement!',
					showAlert: true,
					alertColor: 'error'
				})
				return
			}
			let utm_params = {}

			try {
				utm_params = getUTMParams()
			} catch (e) {
				console.log('Error getting utm params: ' + e)
			}

			this.register({ ...this.user, ...utm_params })
				.then(data => {
					this.isLoading = false
					this.$router.push({
						name: 'create-organization',
						params: {
							ein: this.partnershipInstitution
								? this.partnershipInstitution.ein
								: null,
							skipSearch: true
						}
					})
				})
				.catch(error => {
					this.isLoading = false
					this.setAlert({
						alertText: error.message,
						showAlert: true,
						alertColor: 'error'
					})
				})
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';
@import '@roundupapp/component-library/src/styles/font';

.roundup-logo {
	background: url('../../../assets/roundup_logo.svg');
	background-size: 226px 22px;
	background-repeat: no-repeat;
	background-position: center;
	width: 226px;
	height: 22px;
	margin: 40px 0px 40px;
}

.infoBar {
	text-align: left;
}
.tos {
	color: #484a50;
	font-size: 14px;
	margin-top: 20px;
}
.existing-account {
	color: #484a50;
}
.attention_call_16 {
	margin-bottom: 0px;
	color: #484a50;
	font-size: 16px;
	font-weight: $font-medium;
}

.attention_call_18 {
	margin-top: 5px;
	margin-bottom: 5px;
	color: #484a50;
	font-size: 18px;
	font-weight: $font-medium;
}

.form-title {
	margin-top: 20px !important;
	color: $roundup-charcoal !important;
	font-size: 20px !important;
	font-weight: $font-medium !important;
	flex-direction: column !important;
	align-items: flex-start !important;

	.subtitle {
		font-weight: $font-medium;
		margin-bottom: 0px;
	}
}
.description_text {
	font-size: 16px;
	color: #484a50;
	margin-bottom: 40px;
	padding-right: 50px;
	padding-bottom: 20px;
}
.register-view {
	text-align: left;
	max-width: 600px;
	.register-header {
		p {
			margin: 0px;
		}
	}
}
</style>
